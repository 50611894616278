import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Transition from '../components/Transition/Transition';
import { SolutionMainContainer } from '../components/SolutionTemplate/SolutionTemplate.css';
import SEO from '../components/SEO';
import IntersectionVisible from 'react-intersection-visible';
import { useTextAnimationSecondary } from '../hooks/useTextAnimation';
import Title from '../components/Title/Title';
import downloadIcon from '../../static/images/download-icon.png';
import Video from '../components/Video';
import Accordion from '../components/Accordion';
import Footer from '../components/Footer/Footer';

const SolutionTemplate = (props) => {
  const { location, pageContext, data } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isSecondaryVisible, setIsSecondaryVisible] = useState(false);
  const titleRef = useRef(null);
  const secondaryTitleRef = useRef(null);

  const query = data.content.nodes.find(
    (node) => node.uid === pageContext?.uid
  );

  const pageContent = query?.data;
  
  const {
    benefits,
    benefits_title,
    brochure_link,
    excerpt,
    features,
    features_title,
    logo_link,
    overline,
    title,
    youtube_video_id,
  } = pageContent;

  useTextAnimationSecondary({ element: titleRef }, [isVisible]);

  const titleProps = {
    type: 'h3',
    as: 'h3',
    customClassName: 'HeadingContainer__Title',
    withRedPoint: true,
  };

  return (
    <>
      <SEO
        location={location}
        pathname={location.pathname}
        locale={pageContext?.locale}
      />
      <SolutionMainContainer>
        <Transition>
          <IntersectionVisible onShow={() => setIsVisible(true)}>
            <div className='solution-heading'>
              {overline?.text && (
                <Title ref={titleRef} {...titleProps}>
                  {overline.text}
                </Title>
              )}
              {logo_link?.url && (
                <img
                  src={logo_link.url}
                  alt='Solution logo'
                  className='solution-logo'
                />
              )}
            </div>
            <div className='solution-body'>
              {excerpt?.html && (
                <p
                  className='solution-excerpt'
                  dangerouslySetInnerHTML={{ __html: excerpt.html }}
                />
              )}
              {brochure_link?.url && (
                <a
                  href={brochure_link.url}
                  download
                  rel='noreferrer'
                  target='_blank'
                >
                  <img
                    className={'download-icon'}
                    src={downloadIcon}
                    alt='Download Icon'
                  />
                </a>
              )}
            </div>
            {youtube_video_id?.text && (
              <Video youtubeId={youtube_video_id.text} />
            )}
          </IntersectionVisible>
          <div className='solution-features-container'>
            {features_title?.text && <h1>{features_title.text}</h1>}
            {features?.length > 0 && (
              <div className='solution-features'>
                {features?.map((feature, index) => {
                  return (
                    <div className='feature-card'>
                      {feature?.feature_title?.text && (
                        <h3>{feature.feature_title.text}</h3>
                      )}
                      {feature?.feature_body?.html && (
                        <div
                          key={index}
                          className='solution-feature'
                          dangerouslySetInnerHTML={{
                            __html: feature.feature_body.html,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <IntersectionVisible onShow={() => setIsSecondaryVisible(true)}>
            {benefits_title?.text && (
              <Title ref={titleRef} {...titleProps}>
                {benefits_title.text}
              </Title>
            )}
            <Accordion items={benefits.map(benefit => {
              return {
                title: benefit?.benefit_title?.text,
                body: benefit?.benefit_body?.html,
              };
            })} />
          </IntersectionVisible>
          <Footer />
        </Transition>
      </SolutionMainContainer>
    </>
  );
};

SolutionTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SolutionTemplate;

export const query = graphql`
         query solutionQuery($locale: String!) {
           content: allPrismicSolutionPage(filter: { lang: { eq: $locale } }) {
             nodes {
               id
               uid
               lang
               data {
                 benefits {
                   benefit_title {
                     text
                   }
                 }
                 benefits_title {
                   text
                 }
                 brochure_link {
                   url
                 }
                 excerpt {
                   html
                 }
                 features {
                   feature_body {
                     html
                   }
                   feature_title {
                     text
                   }
                 }
                 features_title {
                   text
                 }
                 logo_link {
                   url
                 }
                 overline {
                   text
                 }
                 title {
                   text
                 }
                 youtube_video_id {
                   text
                 }
               }
             }
           }
         }
       `;
