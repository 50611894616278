import styled from '@emotion/styled';
import { theme } from '../../styles';

export const AccordionContainer = styled.div`
  padding-top: 45px;
  margin-top: 80px;
  border-top: 3px solid black;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.md}) {
    padding-top: 30px;
    margin-top: 40px;
  }

  .accordion-item {
    display: flex;
    flex-direction: column;
  }

  .accordion-headers {
    width: 40%;

    @media (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  }

  .accordion-item-body {
    width: 60%;
    display: none;

    @media (max-width: ${theme.breakpoints.md}) {
      &:not(.is-mobile) {
        display: none;
      }

      &.is-mobile {
        display: block;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    @media (min-width: ${theme.breakpoints.md}) {
      &:not(.is-mobile) {
        display: block;
      }
    }
  }

  .accordion-item {
    opacity: 0.5;
    display: flex;
  }

  .accordion-item-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    gap: 40px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 20px;
      gap: 20px;
    }

    * {
      margin: 0;
    }

    h3 {
      margin-top: 2px;
    }
  }

  .accordion-item.is-active {
    opacity: 1;
  }
`;
