import styled from '@emotion/styled';
import { theme } from '../../styles';

export const SolutionMainContainer = styled.div`
  position: relative;
  margin-top: calc(45px + 40px);

  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: calc(65px + 40px);
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin-top: calc(71px + 80px);
  }

  .solution-heading {
    display: flex;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: start;
    }

    img {
      max-height: 60px;
      width: auto;

      @media (max-width: ${theme.breakpoints.md}) {
        max-height: 45px;
      }
    }
  }

  .solution-body {
    margin-top: 175px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 60px;
      margin-bottom: 20px;
    }

    .solution-excerpt {
      max-width: 670px;
      font-size: 22px;
    }

    a {
      cursor: pointer;

      @media (max-width: ${theme.breakpoints.md}) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .solution-features-container {
    margin-top: 80px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 40px;
    }

    h1 {
      font-size: 3rem;
      font-family: ${theme.fontFamily.tertiary};
      max-width: 800px;
      margin-bottom: 80px;

      @media (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 40px;
        font-size: 2rem;
      }
    }
  }

  .solution-features {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 80px;
  }

  .feature-card {
    max-width: 400px;
    width: calc(33% - 0.8rem);

    @media (max-width: ${theme.breakpoints.md}) {
      width: calc(50% - 0.8rem);
    }

    @media (max-width: ${theme.breakpoints.s}) {
      width: 100%;
    }

    h3 {
      font-size: 21px;
      font-family: ${theme.fontFamily.secondary};
      padding: 1rem;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      text-transform: uppercase;
    }

    p {
      font-size: 22px;
    }
  }

  .SolutionTemplate_Body {
    /* font-size: ${theme.fonts.m}; */

    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 40px;
    }

    h1 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxxl_small, 0.2)};
      margin-bottom: 20px;
    }

    h2 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxl_large, 0.2)};
      margin-bottom: 20px;
    }

    h3 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxl_medium, 0.2)};
      margin-bottom: 20px;
    }

    h4 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxl_small, 0.2)};
      margin-bottom: 20px;
      line-height: 1.4;
    }

    h5 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xl_medium, 0.2)};
      margin-bottom: 20px;
      line-height: 1.4;
    }

    h6 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.l, 0.2)};
      margin-bottom: 20px;
      line-height: 1.4;
    }

    li {
      padding-left: 25px;
      margin-bottom: 15px;
      position: relative;
      font-weight: 400;

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: -20px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: ${theme.colors.primaryred};
      }
    }

    ul {
      list-style: none;
    }

    a {
      text-decoration: underline;
    }
  }
`;

// export const ArticleTemplateBodyImage = styled(Image)`
//   max-width: 500px;
//   width: 100%;
//   margin-top: 30px;
// `;
