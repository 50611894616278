import React, { useState, useEffect } from 'react';
import { AccordionContainer } from './style.css';

const CircleIcon = () => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 10.7734C20 16.2963 15.5228 20.7734 10 20.7734C4.47715 20.7734 0 16.2963 0 10.7734C0 5.25059 4.47715 0.773438 10 0.773438C15.5228 0.773438 20 5.25059 20 10.7734Z'
        fill='#001DF5'
      />
    </svg>
  );
};

const Accordion = ({ items }) => {
  // Initially, no item is active
  const [active, setActive] = useState(null);

  // Check if any item has a body
  const itemsHaveBody = items.some((item) => item.body);

  useEffect(() => {
    // If no items have a body, set all items as active by default
    if (!itemsHaveBody) {
      setActive(items.map((_, index) => index)); // Set all indexes as active
    } else {
      setActive(0); // Set the first item as active
    }
  }, [items, itemsHaveBody]);

  return (
    <AccordionContainer>
      <div className='accordion-headers'>
        {items?.map((item, index) => (
          <div
            role={itemsHaveBody ? 'button' : undefined}
            onClick={itemsHaveBody ? () => setActive(index) : undefined}
            className={`accordion-item ${
              Array.isArray(active) || index === active ? 'is-active' : ''
            }`}
            key={index}
          >
            <div className='accordion-item-header'>
              <CircleIcon />
              <h3>{item.title}</h3>
            </div>
            {itemsHaveBody && item.body && (
              <div
                className={`accordion-item-body is-mobile`}
                dangerouslySetInnerHTML={{ __html: item.body }}
              />
            )}
          </div>
        ))}
      </div>
      {itemsHaveBody && items[active]?.body && (
        <div
          className={`accordion-item-body`}
          dangerouslySetInnerHTML={{ __html: items[active]?.body }}
        />
      )}
    </AccordionContainer>
  );
};

export default Accordion;
