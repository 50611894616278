import React, { useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { VideoContainer } from './style.css'

// Define the SVG as a React component
const PlayIcon = () => (
  <svg
    width='101'
    height='101'
    viewBox='0 0 101 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M50.0249 6.96484C41.372 6.96484 32.9134 9.53074 25.7187 14.338C18.5241 19.1454 12.9165 25.9782 9.60519 33.9724C6.29386 41.9667 5.42746 50.7634 7.11557 59.25C8.80367 67.7367 12.9705 75.5322 19.089 81.6508C25.2075 87.7693 33.0031 91.9361 41.4897 93.6242C49.9764 95.3123 58.7731 94.4459 66.7673 91.1346C74.7616 87.8232 81.5944 82.2157 86.4017 75.021C91.209 67.8264 93.7749 59.3678 93.7749 50.7148C93.7749 39.1116 89.1656 27.9836 80.9608 19.7789C72.7561 11.5742 61.6281 6.96484 50.0249 6.96484ZM73.2968 53.5117L35.7968 72.2617C35.3202 72.4999 34.7907 72.6122 34.2585 72.5881C33.7263 72.564 33.2092 72.4042 32.7561 72.124C32.303 71.8438 31.9291 71.4524 31.6699 70.9869C31.4106 70.5215 31.2747 69.9976 31.2749 69.4648V31.9648C31.2752 31.4324 31.4115 30.9088 31.671 30.4438C31.9305 29.9788 32.3044 29.5879 32.7574 29.308C33.2104 29.0281 33.7274 28.8686 34.2593 28.8447C34.7913 28.8207 35.3205 28.9331 35.7968 29.1711L73.2968 47.9211C73.8152 48.1809 74.251 48.5798 74.5557 49.0732C74.8603 49.5666 75.0216 50.135 75.0216 50.7148C75.0216 51.2947 74.8603 51.8631 74.5557 52.3565C74.251 52.8499 73.8152 53.2488 73.2968 53.5086'
      fill='black'
    />
  </svg>
);

const VideoPlayer = ({ youtubeId }) => {
  const [play, setPlay] = useState(false);

  const url = `https://www.youtube.com/watch?v=${youtubeId}`;

  return (
    <VideoContainer className='video-player'>
      <ReactPlayer
        url={url}
        playing={play}
        controls={true}
        light={true}
        playIcon={<PlayIcon />}
        onPlay={() => setPlay(true)}
        onPause={() => setPlay(false)}
        width='100%'
        height='100%'
      />
    </VideoContainer>
  );
};

export default VideoPlayer;
